
import { Component, Prop, Vue } from "vue-property-decorator";
import Quill, { QuillOptionsStatic } from "quill";
import { htmlEditButton } from "quill-html-edit-button";
import { QuillDeltaToHtmlConverter } from "quill-delta-to-html";
const Inline = Quill.import("blots/inline");

class SpanBlot extends Inline {
  static blotName = "span";
  static tagName = "span";

  static create(value: any) {
    const node = super.create();
    if (typeof value === "object") {
      Object.entries(value).forEach(([key, val]) => {
        if (val) {
          node.setAttribute(key, val as string);
        } else {
          node.setAttribute(key, ""); // Explicitly set empty string if no value
        }
      });
    } else if (value) {
      // For boolean-like attributes, just set the attribute without value.
      node.setAttribute(value, "");
    }
    return node;
  }

  // Retrieve all attributes as formats with a properly typed object
  static formats(node: HTMLElement) {
    const attrs: { [key: string]: string } = {}; // Index signature added
    Array.from(node.attributes).forEach((attr) => {
      attrs[attr.name] = attr.value || ""; // Handle boolean attributes
    });
    return attrs;
  }
}

Quill.register(SpanBlot, true);
Quill.register("modules/htmlEditButton", htmlEditButton);

@Component
export default class Wysiwyg extends Vue {
  /**
   * @Prop {string} id
   * quill editor will mount on this element
   * must be unique for every instance of this component
   */
  @Prop() id!: string;
  @Prop() content!: string;
  @Prop() showHtmlButton!: boolean;

  protected quillOptions: QuillOptionsStatic = {
    debug: "warn",
    modules: {
      toolbar: [
        { size: ["small", false, "large", "huge"] },
        "bold", "italic", "underline", "link", "code"
      ]
    },
    theme: "snow",
  };
  protected htmlQuillOptions: QuillOptionsStatic = {
    debug: "warn",
    modules: {
      toolbar: [
        [{ size: ["small", false, "large", "huge"] }],
        ["bold", "italic", "underline"],
        ["link"],
      ],
      htmlEditButton: {}, 
    },
    formats: ["span", "bold", "italic", "underline", "link", "data-nosnippet"],
    theme: "snow",
  };
  protected quill!: Quill;

  protected setDefaults(): void {
    const delta = this.quill.clipboard.convert(
      this.content as
        | { html?: string | undefined; text?: string | undefined }
        | undefined
    );

    this.quill.setContents(delta, "api");
  }

  protected addEditorListener(): void {
    this.quill.on("text-change", this.onEditorChange);
  }

  public onEditorChange(): void {
    const quillDelta: any = this.quill.getContents();
    const quillToHtml = new QuillDeltaToHtmlConverter(quillDelta.ops, {});
    const html = quillToHtml.convert();
    this.$emit("sendUpdates", this.id, html);
  }

  mounted() {
    this.$nextTick(() => {
       this.quill = new Quill("#" + this.id, this.showHtmlButton ? this.htmlQuillOptions : this.quillOptions);
      this.setDefaults();
      this.addEditorListener();
    });
  }

  beforeDestroy() {
    this.quill.off("text-change", (): void => {
      return;
    });
  }
}
